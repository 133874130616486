import request from "@/utils/request";

// 查询门店基本信息列表
export function listBase (query) {
  return request({
    url: "/store/store-base/list",
    method: "get",
    params: query,
  });
}

// 查询门店基本信息详细
export function getBase (storeId) {
  return request({
    url: "/store/store-base/" + storeId,
    method: "get",
  });
}

// 新增门店基本信息
export function addBase (data) {
  return request({
    url: "/store/store-base",
    method: "post",
    data: data,
  });
}

// 修改门店基本信息
export function updateBase (data) {
  return request({
    url: "/store/store-base",
    method: "put",
    data: data,
  });
}

// 删除门店基本信息
export function delBase (storeId) {
  return request({
    url: "/store/store-base/" + storeId,
    method: "delete",
  });
}

// 查询门店基本信息列表
export function all () {
  return request({
    url: "/store/store-base/all",
    method: "get"
  });
}
// 通过门店id获取人员列表
export function getPersonListByStoreId (storeId) {
  return request({
    url: "/store/person-base/getPersonListByStoreId/",
    method: "get",
    params: { storeId },
  });
}

export function getPersonLevelList () {
  return request({
    url: "/store/person-level/getPersonLevelList/",
    method: "get",
  });
}

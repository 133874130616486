<template>
  <span>{{ storeName }}</span>
</template>

<script setup>
import { getBase } from "@/api/store/base";

const props = defineProps({
  // 当前的值
  storeId: [Number, String]
});
const storeName = ref('')
const computedStoreName =  computed(async () => {
  if (!props.storeId) {
    return;
  }
 await getBase(props.storeId).then(res => {
    const data = res.data.storeName
   storeName.value = data
  });
});
console.log(computedStoreName.value);
</script>

<style scoped lang="scss">

</style>

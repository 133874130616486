<template>
  <router-view />
</template>

<script setup>
import useSettingsStore from '@/store/modules/settings'
import { handleThemeStyle } from '@/utils/theme'
import Cookies from "js-cookie";

onMounted(() => {
  nextTick(() => {
    handleThemeStyle(useSettingsStore().theme)
  })

})
</script>
